<template>
  <div>
    <el-row :gutter="10" style="min-height: 60px;" v-loading="productConfig.isLoadData">
      <template v-if="productConfig.data && productConfig.data.length > 0">
        <el-col :span="8"
                class="goods-info-item sctp-mar-b10"
                v-for="goodsInfo in productConfig.data"
                :key="goodsInfo.goodsid"
        >
          <a target="_blank" :href="'/sourcecode/view/' + goodsInfo.goodsid">
            <div class="pad8 sctp-bg-white sctp-flex sctp-flex-column">
              <el-image
                style="width: 100%;height: 174px;"
                :src="goodsInfo.goodslogo" fit="cover"
              ></el-image>
              <div style="height: 34px;" class="sctp-ellipsis--l2 sctp-mar-tb5">
                {{ goodsInfo.goodsname }}
              </div>
              <div class="divider"></div>
              <div class="flex flex-sb fz-12">
                <div>
                  <span>{{ goodsInfo.codeTypeStr }}</span>
                  <span class="sctp-mar-l10">({{
                      goodsInfo.copyrightflag === 1 ? '有' : '无'
                    }}版权证)</span>
                </div>
                <div>
                  <span>{{ getCity(goodsInfo.cityCode) }}</span>
                </div>
              </div>
              <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                <div class="sctp-red">{{
                    goodsInfo.saleprice ? `¥${goodsInfo.saleprice}` : '免费'
                  }}
                </div>
                <div class="sctp-font-14">{{ goodsInfo.shopName }}</div>
              </div>
              <div @click.prevent="" class="flex flex-sb pad-lr5">
                <div></div>
                <div>
                  <el-dropdown @command="handleCommand($event, goodsInfo)" size="mini">
                    <i class="el-icon-more"></i>
                    <el-dropdown-menu slot="dropdown">
                      <template v-for="item in dropdownOptions(goodsInfo)">
                        <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                      </template>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </a>
        </el-col>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </el-row>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
      <el-pagination
        :current-page="productConfig.pageConfig.page"
        :page-size="productConfig.pageConfig.limit"
        @size-change="productConfig.pageConfig.handleSizeChange"
        @current-change="productConfig.pageConfig.handleCurrentChange"
        :page-sizes="productConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="productConfig.pageConfig.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {collection} from "../../../../apis";
import area from "../../../../resources/js/area";

export default {
  name: "product",
  data() {
    return {
      productConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {page, limit} = this.productConfig.pageConfig;
          this.productConfig.isLoadData = true;
          collection.getProduct({
            userId: this.user.userId,
            page, limit,
          }).then(res => {
            let {data, count = 0} = res;
            this.productConfig.data = data;
            this.productConfig.pageConfig.total = count;
          }).finally(() => {
            this.productConfig.isLoadData = false;
          })
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.productConfig.pageConfig.limit = limit;
            this.productConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.productConfig.pageConfig.page = page;
            this.productConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    handleCommand: (command, item) => {
      command.command(item);
    },
  },
  beforeMount() {
    this.productConfig.loadData();
  },
  computed: {
    dropdownOptions() {
      return function (item) {
        let options = [];
        options = [
          {
            label: '取消收藏', command: () => {
              collection.addFavorite({
                userId: (this.user && this.user.userId) || null,
                targetId: item.goodsid,
                targetType: 2
              }).then(res => {
                const {data} = res;
                if (data) {
                  this.$message.success('收藏成功')
                } else {
                  this.$message.success('取消收藏成功')
                }
                this.productConfig.loadData();
              });
            }
          },
        ];
        return options;
      }
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pad8 {
  padding: 8px;
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}

</style>
